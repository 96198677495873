<template>
  <div>
    <!-- 表单 -->
    <van-form ref="form">
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <van-field v-model="form.stuNo" placeholder="请填写学号" :disabled="true" label="学号" />
        <van-field v-model="form.stuName" placeholder="请填写姓名" :disabled="true" label="姓名" />
        <van-field v-model="form.sexName" :disabled="true" label="性别" />
        <van-field v-model="form.unitName" :disabled="true" label="院系" />
        <van-field v-model="form.specName" :disabled="true" label="专业" />
        <van-field v-model="form.className" :disabled="true" label="班级" />
        <van-field v-model="form.gradeNo" :disabled="true" label="年级" />
        <van-field v-model="form.enterMon" :disabled="true" label="入学年月" />
        <custom-date-picker v-model="form.bdDate" :disabled="true" label="出生日期" />
        <van-field v-model="form.nativePlaceName" :disabled="true" label="民族" />

        <van-field v-model="form.cardNo" :disabled="true" label="身份证号" />
        <van-field required :rules="[{ required: true, message: '请填写电话' },
        {pattern: /^1[3-9]\d{9}$/, message: '请输入合法的手机号/电话号'}]" v-model="form.telNo" placeholder="请填写电话" label="联系电话" />
      </van-cell-group>

      <van-cell-group title="家庭经济情况">
        <van-field required :rules="[{ required: true ,pattern:/^[0-9]{1,2}$/ }]" v-model="form.famCnt" placeholder="请填写家庭人口总数" label="家庭人口总数" />
        <van-field required :rules="[{ required: true ,pattern:/^[0-9]{1,10}$/  }]" v-model="form.gmi" placeholder="请填写人均年收入" label="人均年收入" />
        <van-field required :rules="[{ required: true  ,pattern:/^[0-9]{1,10}$/ }]" v-model="form.annualIncome" placeholder="请填写人均月收入" label="人均月收入" />
        <van-field required :rules="[{ required: true  }]" v-model="form.sor" placeholder="请填写收入来源" label="收入来源" />
        <van-field required :rules="[{ required: true  }]" v-model="form.homeAddress" placeholder="请填写家庭住址" label="家庭住址" />
        <van-field v-model="form.homePostcode" placeholder="请填写邮政编码" label="邮政编码" />
      </van-cell-group>

      <van-cell-group title="家庭成员情况">
        <van-collapse v-model="activeNames">
          <van-collapse-item v-for="item,index in form.jtcyxxList" :key="index" :title="item.name+'('+item.relation+')'" :name="index">
            <div> <span class="collapse-title">年龄:</span><span class="collapse-text">{{item.age}}</span></div>
            <div> <span class="collapse-title">工作（学习单位）:</span><span class="collapse-text">{{item.unitName}}</span></div>
            <van-button style="height:26px" round type="warning" plain hairline @click="familyDelete(index,item.id)">删除</van-button>
            <van-button style="height:26px;margin-left: 5px;margin-top: 10px;" round type="primary" plain hairline @click="familyEdit(item,index)">编辑</van-button>
          </van-collapse-item>
        </van-collapse>
        <div class="form-addList-button">
          <van-button type="primary" size="small" native-type="button" style="height:36px" @click="familyAdd">添加家庭成员</van-button>
        </div>
      </van-cell-group>
      <!-- 申请理由 -->
      <van-cell-group title="申请理由">
        <van-field required v-model="form.applyReason" :rules="[{ required: true  } ]" rows="3" class="textarea-style" arrow-direction="" label="申请理由" type="textarea" placeholder="请填写申请理由" />
      </van-cell-group>
      <!-- 证明附件 -->
      <van-cell-group title="证明附件" class="upload-file" style="margin-bottom:65px;">
        <file-upload v-if="refsh" accept=".doc,.docx,.pdf,.xls,.xlsx,.png,.gif,.jpeg" v-model="form.fileId" miz="JZGL" biz="storage" :multiple="3" v-on:uploadResponse="uploadResponse" list-type="list" :auto-upload="true" :max-size="1">
          <span slot="tip" class="upload-tips">
            支持上传任意文件,文件最大1M
          </span>
        </file-upload>
      </van-cell-group>
      <!-- 底部 -->
      <div class="submit-button shadow-style" v-if="form.status === 1 || form.status === '1'">
        <div>
          <van-button round block type="primary" :loading="loading" @click="doSubmit(true)">提 交</van-button>
        </div>
      </div>
      <div class="submit-save shadow-style" v-else>
        <van-button round block type="info" class="submit-save-savebtn" color="rgba(235, 123, 77, 1)" :loading="loading" @click="doSubmit(false)">保 存</van-button>
        <van-button round block type="primary" class="submit-save-subbtn" :loading="loading" @click="doSubmit(true)">提 交</van-button>
      </div>
    </van-form>

    <!-- 家庭成员信息弹出层 -->
    <van-popup v-model="familyInfoShow" position="bottom" closeable @close="familyclose" :style="{ height: ' 324px' }">
      <div class="van-popup-title-div"><b>{{isFamilyAdd?'新增家庭成员':'编辑家庭成员'}}</b></div>
      <van-form @submit="familySubmit">
        <van-field v-model="familyForm.name" placeholder="请填写姓名" label="姓名" :rules="[{ required: true, message: '请填写姓名' }]" />
        <van-field v-model="familyForm.age" placeholder="请填写年龄" label="年龄" :rules="[{ required: true, message: '请填写年龄' ,pattern:/^[0-9]{1,5}$/ }]" />
        <van-field v-model="familyForm.relation" placeholder="请填写与本人关系" label="与本人关系" :rules="[{ required: true, message: '请填写与本人关系' }]" />
        <van-field v-model="familyForm.unitName" placeholder="请填写工作单位" :rules="[{ required: true, message: '请填写工作单位' }]" label="工作单位" />
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit" style="font-size:15px">保 存</van-button>
        </div>
      </van-form>
    </van-popup>

  </div>
</template>

<script>
import { delJtcy } from "@/api/modules/award/pksxx";
import { getGjzxjById, save } from "@/api/modules/award/gjzxj";
import { getStuinfo } from "@/api/modules/student/stuInfo";
import { mapGetters } from "vuex";
import { validCustom } from "@/utils/index";
import fileUpload from "@/components/FileUpload";

export default {
  name: "NationHelp",
  components: { fileUpload },
  data() {
    return {
      loading: false,
      refsh: true,
      sexDict: [
        { label: "男", value: "1" },
        { label: "女", value: "2" },
      ],
      isNotDict: [
        { label: "是", value: true },
        { label: "否", value: false },
      ],
      activeNames: [0],
      form: {
        id: "",
        year: new Date().getFullYear().toString(),
        schCode: "13954",
        // 学校名称
        schName: "甘肃农业职业技术学院",
        // 院系代码
        unitNo: "",
        // 院系名称
        unitName: "",
        // 专业代码
        specCode: "",
        // 专业名称
        specName: "",
        // 年级
        gradeNo: "",
        // 班级号
        classNo: "",
        // 班级名称
        className: "",
        // 班主任工号
        classTchStaffNo: "",
        // 班主任姓名
        classTchStaffName: "",
        // 辅导员号
        conslrStaffNo: "",
        // 辅导院姓名
        conslrStaffName: "",
        // 班长号
        monitrStuNo: "",
        // 班长姓名
        monitrStuName: "",
        // 入学年月
        enterMon: "",
        // 学号
        stuNo: "",
        // 姓名
        stuName: "",
        // 性别名称
        sexName: "",
        // 出生日期
        bdDate: "",
        // 籍贯地码
        nativePlaceNo: "",
        // 籍贯地码
        nativePlace: "",
        // 身份证号
        cardNo: "",
        // 家庭人口数
        famCnt: "",
        // 联系电话
        telNo: "",
        // 详细家庭地址
        homeAddress: "",
        // 详细家庭地址
        nativePlaceName: "",
        //民族
        polName: "",
        //政治面貌
        gmi: "", //月总收入
        // 人均年收入
        annualIncome: "",
        // 收入来源
        sor: "",
        // 个人承诺
        applyReason: "",
        // 审批状态
        spzt: "",
        jtcyxxList: [],
        status: 0,
        fileId: [],
      },
      familyForm: {
        name: "",
        cardNo: "",
        age: "",
        relation: "",
        unitName: "",
        occupation: "",
        annualIncome: "",
        health: "",
        typeNo: "GJZXJ",
      },
      familyInfoShow: false,
      isFamilyAdd: false,
      editIndex: 0,
    };
  },
  computed: {
    ...mapGetters(["user", "roles"]),
  },
  created() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        //学生修改
        getGjzxjById({ id: this.$route.query.id }).then((res) => {
          this.form = res;
          if (res.fileId) {
            this.form.fileId = res.fileId;
          }

          this.refsh = false;
          this.$nextTick(() => {
            this.refsh = true;
          });
        });
      } else {
        getStuinfo({ stuNo: "" }).then((stu) => {
          if (stu) {
            this.updateFrom(stu);
          } else {
            this.$message.error("未检索到您的学生信息！");
            return;
          }
        });
      }
    },
    doSubmit(validate) {
      if (validate) {
        this.$refs.form.validate().then(
          (res) => {
            // 验证通过数据提交
            this.$dialog
              .confirm({
                title: "提示",
                message: "你确定要进行提交操作吗？",
              })
              .then(() => {
                this.loading = true;
                let form = Object.assign({}, this.form);
                form.status = 1;
                save(form).then((res) => {
                  this.$notify({
                    message: "操作成功",
                    type: "success",
                    duration: 2500,
                  });
                  this.cancel();
                  this.loading = false;
                });
              })
              .catch(() => {
                // on cancel
              });
          },
          (err) => {
            this.$notify({
              type: "warning",
              message: "有提交项未填写！",
              duration: 1500,
            });
          }
        );
      } else {
        this.loading = true;
        let form = Object.assign({}, this.form);
        form.status = 0;
        save(form).then((res) => {
          this.$notify({
            message: "操作成功",
            type: "success",
            duration: 2500,
          });
          this.cancel();
          this.loading = false;
        });
      }
    },
    // 文件上传后的回调
    uploadResponse(fileIds, data) {
      if (fileIds.length) {
        this.form.fileId = fileIds;
      }
    },

    // ------家庭信息
    familySubmit() {
      if (this.isFamilyAdd) {
        this.form.jtcyxxList.push(this.familyForm);
      } else {
        this.form.jtcyxxList.splice(this.editIndex, 1, this.familyForm);
      }
      this.familyclose();
    },
    familyAdd() {
      this.isFamilyAdd = true;
      this.familyInfoShow = true;
    },
    familyEdit(data, index) {
      this.familyForm = data;
      this.editIndex = index;
      this.isFamilyAdd = false;
      this.familyInfoShow = true;
    },
    familyDelete(index, id) {
      if (id) {
        delJtcy(id).then((res) => {
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2000,
          });
          this.form.jtcyxxList.splice(index, 1);
        });
      } else {
        this.form.jtcyxxList.splice(index, 1);
      }
    },
    familyclose() {
      this.familyInfoShow = false;
      this.familyForm = {
        name: "",
        cardNo: "",
        age: "",
        relation: "",
        unitName: "",
        occupation: "",
        annualIncome: "",
        health: "",
        typeNo: "GJZXJ",
      };
    },
    updateFrom(stuInfo) {
      //院系所号
      this.form.unitNo = stuInfo.collNo; // 院系代码
      this.form.unitName = stuInfo.collName; // 院系名称
      this.form.specCode = stuInfo.sqecCode; // 专业代码
      this.form.specName = stuInfo.sqecName; // 专业名称
      this.form.gradeNo = stuInfo.gradeNo; // 年级
      this.form.classNo = stuInfo.classNo; // 班级号
      this.form.className = stuInfo.className; // 班级名称
      this.form.classTchStaffNo = stuInfo.classTchStaffNo; // 班主任工号
      this.form.classTchStaffName = stuInfo.classTchStaffName; // 班主任姓名
      this.form.conslrStaffNo = stuInfo.conslrStaffNo; // 辅导员号
      this.form.conslrStaffName = stuInfo.conslrStaffName; // 辅导院姓名
      //this.form.monitrStuNo = stuInfo.monitorStuNo// 班长号
      //this.form.monitrStuName = stuInfo.collName// 班长姓名
      this.form.enterMon = stuInfo.enterMon; // 入学年月
      this.form.stuNo = stuInfo.stuNo; // 学号
      this.form.stuName = stuInfo.stuName; // 姓名
      this.form.sexCode = stuInfo.sexCode; // 性别码
      this.form.sexName = stuInfo.sexName; // 性别名称
      this.form.bdDate = stuInfo.bdDate; // 出生日期
      this.form.nativePlaceNo = stuInfo.nativePlaceNo; // 籍贯地码
      this.form.nativePlace = stuInfo.nativePlace; // 籍贯地码
      this.form.cardNo = stuInfo.cardNo; // 身份证号
      this.form.nativePlaceName = stuInfo.nativePlaceName; // 民族
      this.form.polName = stuInfo.polName; // 政治面貌
      this.form.schName = stuInfo.schName; // 学校
    },
    cancel() {
      this.form = {
        id: "",
        year: new Date().getFullYear().toString(),
        schCode: "13954",
        // 学校名称
        schName: "甘肃农业职业技术学院",
        // 院系代码
        unitNo: "",
        // 院系名称
        unitName: "",
        // 专业代码
        specCode: "",
        // 专业名称
        specName: "",
        // 年级
        gradeNo: "",
        // 班级号
        classNo: "",
        // 班级名称
        className: "",
        // 班主任工号
        classTchStaffNo: "",
        // 班主任姓名
        classTchStaffName: "",
        // 辅导员号
        conslrStaffNo: "",
        // 辅导院姓名
        conslrStaffName: "",
        // 班长号
        monitrStuNo: "",
        // 班长姓名
        monitrStuName: "",
        // 入学年月
        enterMon: "",
        // 学号
        stuNo: "",
        // 姓名
        stuName: "",
        // 性别名称
        sexName: "",
        // 出生日期
        bdDate: "",
        // 籍贯地码
        nativePlaceNo: "",
        // 籍贯地码
        nativePlace: "",
        // 身份证号
        cardNo: "",
        // 家庭人口数
        famCnt: "",
        // 联系电话
        telNo: "",
        // 详细家庭地址
        homeAddress: "",
        // 详细家庭地址
        nativePlaceName: "",
        //民族
        polName: "",
        //政治面貌
        gmi: "", //月总收入
        // 人均年收入
        annualIncome: "",
        // 收入来源
        sor: "",
        // 个人承诺
        applyReason: "",
        // 审批状态
        spzt: "",
        jtcyxxList: [],
        status: 0,
        fileId: [],
      };
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.van-field__label {
  width: 110px;
}
.van-cell {
  font-size: 15px;
  line-height: 32px;
  height: 50px;
}
.van-cell__left-icon,
.van-cell__right-icon {
  line-height: 32px;
}
.textarea-style {
  height: 100% !important;
}

::v-deep.submit-save {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  line-height: var(--footer-height);
  text-align: center;
  &-subbtn {
    margin-left: 5%;
  }

  .van-button {
    margin-top: 10px;
    margin-bottom: 9px;
    width: 40%;
  }

  .van-button--block {
    display: inline-block !important;
  }
}
</style>