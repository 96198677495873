import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/jz/gjzxj',
    method: 'post',
    data
  })
}

export function del(ids) {
  return request({
    url: 'api/jz/gjzxj/',
    method: 'delete',
    data: ids
  })
}

export function edit(data) {
  return request({
    url: 'api/jz/gjzxj',
    method: 'put',
    data
  })
}

export function getGjzxjById(params) {
  return request({
    url: 'api/jz/gjzxj/gjzxjById',
    method: 'get',
    params
  })
}

export function save(data) {
  return request({
    url: 'api/jz/gjzxj/gjzxjSave',
    method: 'put',
    data
  })
}

export function downloadPdf(id) {
  return request({
    url: 'api/jz/gjzxj/pdf/' + id,
    method: 'get',
    responseType: 'blob'
  })
}

export default { add, edit, del,downloadPdf }
